@import url('https://fonts.googleapis.com/css2?family=Oswald:wght@200;300;400;500;600;700&display=swap');

:root {
  font-family: 'Oswald', sans-serif;

  --color-1: rgba(16, 25, 38, 1);
  --color-2: rgba(5, 73, 89, 1);
  --color-3: rgba(2, 103, 114, 1);
  --color-4: rgba(42, 191, 191, 1);
  --color-5: rgba(53, 242, 223, 1);
}
