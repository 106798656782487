* {
  box-sizing: border-box;
  padding: 0;
  margin: 0;
  scroll-behavior: smooth;
}

/* BUFFERS SCROLL SIZE OF NAVBAR */
html {
  scroll-padding-top: 54px;
}

body {
  background-image: url('./assets/bg_dark_pattern.png');
}

p,
h1,
h2,
h3,
a {
  opacity: 66%;
}
a {
  color: unset;
  text-decoration: none;
}

.app {
  display: flex;
}

.section__heading {
  text-align: center;
  font-size: 4rem;
  letter-spacing: -0.31rem;
  opacity: 100%;
  font-family: 'Permanent Marker', sans-serif;
  color: rgba(0, 215, 255, 1);
  text-shadow: 2px 2px 10px #121212;
}
