/* down facing waves */

svg {
  margin: -2.5px 0;
}

.path-0 {
  animation: pathAnim-0 4s;
  animation-timing-function: linear;
  animation-iteration-count: infinite;
}
@keyframes pathAnim-0 {
  0% {
    d: path(
      'M 0,500 C 0,500 0,100 0,100 C 71.25899617708718,112.99997533604636 142.51799235417437,125.99995067209274 188,124 C 233.48200764582563,122.00004932790726 253.1870267603897,105.00017264767541 296,93 C 338.8129732396103,80.99982735232459 404.73390060426686,73.99935873720558 473,79 C 541.2660993957331,84.00064126279442 611.8773708225427,101.00239240350227 659,105 C 706.1226291774573,108.99760759649773 729.7566161055618,99.99107164878531 783,87 C 836.2433838944382,74.00892835121469 919.0961647552101,57.033321001356526 974,66 C 1028.90383524479,74.96667899864347 1055.8587248735973,109.87564434578863 1104,110 C 1152.1412751264027,110.12435565421137 1221.4689357504008,75.46410161548897 1281,68 C 1340.5310642495992,60.535898384511036 1390.2655321247996,80.26794919225551 1440,100 C 1440,100 1440,500 1440,500 Z'
    );
  }
  25% {
    d: path(
      'M 0,500 C 0,500 0,100 0,100 C 38.51848563324701,80.05036379331608 77.03697126649402,60.100727586632146 140,70 C 202.96302873350598,79.89927241336785 290.37060056727097,119.64745344678752 347,125 C 403.62939943272903,130.35254655321248 429.48062646442224,101.30945862621779 471,96 C 512.5193735355778,90.69054137378221 569.7068935750401,109.11471204834136 634,112 C 698.2931064249599,114.88528795165864 769.6917992354174,102.23169318041683 822,97 C 874.3082007645826,91.76830681958317 907.5259094832901,93.95851522999136 949,89 C 990.4740905167099,84.04148477000864 1040.2045628314218,71.93424589961772 1095,76 C 1149.7954371685782,80.06575410038228 1209.6558391910226,100.3045011715378 1268,107 C 1326.3441608089774,113.6954988284622 1383.1720804044887,106.8477494142311 1440,100 C 1440,100 1440,500 1440,500 Z'
    );
  }
  50% {
    d: path(
      'M 0,500 C 0,500 0,100 0,100 C 57.98988777901097,103.85053644099149 115.97977555802194,107.70107288198298 166,111 C 216.02022444197806,114.29892711801702 258.0707855469232,117.04624491305955 314,117 C 369.9292144530768,116.95375508694045 439.7370822542854,114.11394746577876 496,100 C 552.2629177457146,85.88605253422124 594.9808854359354,60.49796522382539 640,71 C 685.0191145640646,81.50203477617461 732.3393760019733,127.89419163891972 784,129 C 835.6606239980267,130.10580836108028 891.6616105561719,85.92526822049574 946,78 C 1000.3383894438281,70.07473177950426 1053.0141817733381,98.4047354790973 1103,115 C 1152.9858182266619,131.5952645209027 1200.2816623504748,136.45578986311506 1256,132 C 1311.7183376495252,127.54421013688494 1375.8591688247625,113.77210506844247 1440,100 C 1440,100 1440,500 1440,500 Z'
    );
  }
  75% {
    d: path(
      'M 0,500 C 0,500 0,100 0,100 C 43.224565297817236,75.56576643235911 86.44913059563447,51.13153286471821 133,63 C 179.55086940436553,74.86846713528179 229.42804291527932,123.03963497348624 294,126 C 358.5719570847207,128.96036502651376 437.8386977432482,86.70992724133677 491,72 C 544.1613022567518,57.29007275866322 571.2171661117278,70.12065606116661 615,77 C 658.7828338882722,83.87934393883339 719.2926378098409,84.80744851399679 782,90 C 844.7073621901591,95.19255148600321 909.6122826489085,104.64954988284622 963,99 C 1016.3877173510915,93.35045011715378 1058.2582315945244,72.59435195461833 1103,63 C 1147.7417684054756,53.405648045381675 1195.354790972993,54.97304229868048 1252,63 C 1308.645209027007,71.02695770131952 1374.3226045135034,85.51347885065977 1440,100 C 1440,100 1440,500 1440,500 Z'
    );
  }
  100% {
    d: path(
      'M 0,500 C 0,500 0,100 0,100 C 71.25899617708718,112.99997533604636 142.51799235417437,125.99995067209274 188,124 C 233.48200764582563,122.00004932790726 253.1870267603897,105.00017264767541 296,93 C 338.8129732396103,80.99982735232459 404.73390060426686,73.99935873720558 473,79 C 541.2660993957331,84.00064126279442 611.8773708225427,101.00239240350227 659,105 C 706.1226291774573,108.99760759649773 729.7566161055618,99.99107164878531 783,87 C 836.2433838944382,74.00892835121469 919.0961647552101,57.033321001356526 974,66 C 1028.90383524479,74.96667899864347 1055.8587248735973,109.87564434578863 1104,110 C 1152.1412751264027,110.12435565421137 1221.4689357504008,75.46410161548897 1281,68 C 1340.5310642495992,60.535898384511036 1390.2655321247996,80.26794919225551 1440,100 C 1440,100 1440,500 1440,500 Z'
    );
  }
}

.path-1 {
  animation: pathAnim-1 4s;
  animation-timing-function: linear;
  animation-iteration-count: infinite;
}
@keyframes pathAnim-1 {
  0% {
    d: path(
      'M 0,500 C 0,500 0,200 0,200 C 71.0387470711555,201.85315082007645 142.077494142311,203.70630164015293 185,209 C 227.922505857689,214.29369835984707 242.72877050191153,223.0279442594648 296,218 C 349.2712294980885,212.9720557405352 441.0074238500431,194.18192132198791 506,190 C 570.9925761499569,185.81807867801209 609.2415340979159,196.24437045258355 661,201 C 712.7584659020841,205.75562954741645 778.0264397582933,204.8405968676779 826,204 C 873.9735602417067,203.1594031323221 904.652706868911,202.39324207670487 960,208 C 1015.347293131089,213.60675792329513 1095.3627327660624,225.58643482550255 1145,219 C 1194.6372672339376,212.41356517449745 1213.8963620668394,187.261018621285 1258,181 C 1302.1036379331606,174.738981378715 1371.0518189665804,187.36949068935752 1440,200 C 1440,200 1440,500 1440,500 Z'
    );
  }
  25% {
    d: path(
      'M 0,500 C 0,500 0,200 0,200 C 62.121938586755434,206.11098779134295 124.24387717351087,212.2219755826859 178,217 C 231.75612282648913,221.7780244173141 277.14642989271186,225.22308546059932 328,227 C 378.85357010728814,228.77691453940068 435.1704032556419,228.88568257491679 491,234 C 546.8295967443581,239.11431742508321 602.1719570847206,249.2341842397336 649,234 C 695.8280429152794,218.7658157602664 734.1417684054753,178.17758046614875 782,173 C 829.8582315945247,167.82241953385125 887.2609692933777,198.05549389567145 945,197 C 1002.7390307066223,195.94450610432855 1060.8143544210138,163.60044395116537 1118,166 C 1175.1856455789862,168.39955604883463 1231.4816130225677,205.54273029966703 1285,217 C 1338.5183869774323,228.45726970033297 1389.2591934887162,214.2286348501665 1440,200 C 1440,200 1440,500 1440,500 Z'
    );
  }
  50% {
    d: path(
      'M 0,500 C 0,500 0,200 0,200 C 51.350302133431995,206.97807374522137 102.70060426686399,213.9561474904427 161,219 C 219.299395733136,224.0438525095573 284.54788506597606,227.1534837834505 344,225 C 403.45211493402394,222.8465162165495 457.1078554692317,215.42991737575537 505,216 C 552.8921445307683,216.57008262424463 595.0206930570971,225.12684671352818 642,225 C 688.9793069429029,224.87315328647182 740.8093723023799,216.06269577013197 796,206 C 851.1906276976201,195.93730422986803 909.7418177333827,184.622370205944 969,177 C 1028.2581822666173,169.377629794056 1088.2233567640894,165.447823406092 1142,168 C 1195.7766432359106,170.552176593908 1243.36475521026,179.586336169688 1292,186 C 1340.63524478974,192.413663830312 1390.3176223948699,196.206831915156 1440,200 C 1440,200 1440,500 1440,500 Z'
    );
  }
  75% {
    d: path(
      'M 0,500 C 0,500 0,200 0,200 C 55.98732272783327,220.3369589345172 111.97464545566655,240.6739178690344 169,232 C 226.02535454433345,223.3260821309656 284.0887409051671,185.64128745837954 327,170 C 369.9112590948329,154.35871254162046 397.6703909236651,160.76093229744728 454,170 C 510.3296090763349,179.23906770255272 595.2296954001727,191.31498335183127 648,187 C 700.7703045998273,182.68501664816873 721.4108274756443,161.97913429522757 771,170 C 820.5891725243557,178.02086570477243 899.12699469725,214.76847946725854 965,228 C 1030.87300530275,241.23152053274146 1084.0811937353558,230.94694783573811 1130,226 C 1175.9188062646442,221.05305216426189 1214.548230361327,221.4437291897891 1265,218 C 1315.451769638673,214.5562708102109 1377.7258848193364,207.27813540510544 1440,200 C 1440,200 1440,500 1440,500 Z'
    );
  }
  100% {
    d: path(
      'M 0,500 C 0,500 0,200 0,200 C 71.0387470711555,201.85315082007645 142.077494142311,203.70630164015293 185,209 C 227.922505857689,214.29369835984707 242.72877050191153,223.0279442594648 296,218 C 349.2712294980885,212.9720557405352 441.0074238500431,194.18192132198791 506,190 C 570.9925761499569,185.81807867801209 609.2415340979159,196.24437045258355 661,201 C 712.7584659020841,205.75562954741645 778.0264397582933,204.8405968676779 826,204 C 873.9735602417067,203.1594031323221 904.652706868911,202.39324207670487 960,208 C 1015.347293131089,213.60675792329513 1095.3627327660624,225.58643482550255 1145,219 C 1194.6372672339376,212.41356517449745 1213.8963620668394,187.261018621285 1258,181 C 1302.1036379331606,174.738981378715 1371.0518189665804,187.36949068935752 1440,200 C 1440,200 1440,500 1440,500 Z'
    );
  }
}

.path-2 {
  animation: pathAnim-2 4s;
  animation-timing-function: linear;
  animation-iteration-count: infinite;
}
@keyframes pathAnim-2 {
  0% {
    d: path(
      'M 0,500 C 0,500 0,300 0,300 C 59.83504747811074,291.81127142680975 119.67009495622148,283.62254285361945 178,278 C 236.32990504377852,272.37745714638055 293.15466765322486,269.32110001233195 334,264 C 374.84533234677514,258.67889998766805 399.71123443087924,251.09305709705268 450,264 C 500.28876556912076,276.9069429029473 576.0003946232581,310.3066715994574 640,313 C 703.9996053767419,315.6933284005426 756.2871870760882,287.68025650511777 809,291 C 861.7128129239118,294.31974349488223 914.8508570723887,328.97230238007154 957,323 C 999.1491429276113,317.02769761992846 1030.3093846343568,270.43053397459613 1090,270 C 1149.6906153656432,269.56946602540387 1237.9116043901838,315.305561721544 1301,328 C 1364.0883956098162,340.694438278456 1402.044197804908,320.347219139228 1440,300 C 1440,300 1440,500 1440,500 Z'
    );
  }
  25% {
    d: path(
      'M 0,500 C 0,500 0,300 0,300 C 48.39462325810827,301.87914662720436 96.78924651621654,303.75829325440867 153,315 C 209.21075348378346,326.24170674559133 273.23763719324205,346.8459736095696 321,333 C 368.76236280675795,319.1540263904304 400.26020471081506,270.8578123073128 457,267 C 513.7397952891849,263.1421876926872 595.7215439634973,303.72277716117895 657,323 C 718.2784560365027,342.27722283882105 758.8536194351955,340.25107904797136 810,331 C 861.1463805648045,321.74892095202864 922.8639782957208,305.2729066469355 973,299 C 1023.1360217042792,292.7270933530645 1061.6904673819215,296.65729436428666 1108,295 C 1154.3095326180785,293.34270563571334 1208.3741521765937,286.09791589591805 1265,286 C 1321.6258478234063,285.90208410408195 1380.8129239117031,292.951042052041 1440,300 C 1440,300 1440,500 1440,500 Z'
    );
  }
  50% {
    d: path(
      'M 0,500 C 0,500 0,300 0,300 C 63.79025773831543,312.343667529905 127.58051547663086,324.6873350598101 179,318 C 230.41948452336914,311.3126649401899 269.4681958317919,285.59432729066464 311,278 C 352.5318041682081,270.40567270933536 396.5467011962017,280.93535577753113 453,279 C 509.4532988037983,277.06464422246887 578.3449993834013,262.66424959921073 645,271 C 711.6550006165987,279.33575040078927 776.0733012701936,310.4076458256259 823,311 C 869.9266987298064,311.5923541743741 899.3617955358243,281.70516709828587 944,274 C 988.6382044641757,266.29483290171413 1048.479516586509,280.77168578123076 1112,287 C 1175.520483413491,293.22831421876924 1242.72013811814,291.2080897767912 1298,292 C 1353.27986188186,292.7919102232088 1396.6399309409298,296.3959551116044 1440,300 C 1440,300 1440,500 1440,500 Z'
    );
  }
  75% {
    d: path(
      'M 0,500 C 0,500 0,300 0,300 C 47.39237883832779,291.5221112344309 94.78475767665557,283.04422246886173 153,288 C 211.21524232334443,292.95577753113827 280.25334813170554,311.34522135898385 328,316 C 375.74665186829446,320.65477864101615 402.2018497965224,311.5748920952028 452,297 C 501.7981502034776,282.4251079047972 574.9392526822049,262.35521026020473 629,268 C 683.0607473177951,273.64478973979527 718.0411394746578,305.0042668639783 780,317 C 841.9588605253422,328.9957331360217 930.8961894191639,321.6277222838821 985,317 C 1039.1038105808361,312.3722777161179 1058.3741028486866,310.48484400049324 1110,309 C 1161.6258971513134,307.51515599950676 1245.6073991860897,306.4329017141448 1306,305 C 1366.3926008139103,303.5670982858552 1403.1963004069553,301.7835491429276 1440,300 C 1440,300 1440,500 1440,500 Z'
    );
  }
  100% {
    d: path(
      'M 0,500 C 0,500 0,300 0,300 C 59.83504747811074,291.81127142680975 119.67009495622148,283.62254285361945 178,278 C 236.32990504377852,272.37745714638055 293.15466765322486,269.32110001233195 334,264 C 374.84533234677514,258.67889998766805 399.71123443087924,251.09305709705268 450,264 C 500.28876556912076,276.9069429029473 576.0003946232581,310.3066715994574 640,313 C 703.9996053767419,315.6933284005426 756.2871870760882,287.68025650511777 809,291 C 861.7128129239118,294.31974349488223 914.8508570723887,328.97230238007154 957,323 C 999.1491429276113,317.02769761992846 1030.3093846343568,270.43053397459613 1090,270 C 1149.6906153656432,269.56946602540387 1237.9116043901838,315.305561721544 1301,328 C 1364.0883956098162,340.694438278456 1402.044197804908,320.347219139228 1440,300 C 1440,300 1440,500 1440,500 Z'
    );
  }
}

.path-3 {
  animation: pathAnim-3 4s;
  animation-timing-function: linear;
  animation-iteration-count: infinite;
}
@keyframes pathAnim-3 {
  0% {
    d: path(
      'M 0,500 C 0,500 0,400 0,400 C 54.24515969909976,405.7955851522999 108.49031939819952,411.5911703045998 152,418 C 195.50968060180048,424.4088296954002 228.28388210630163,431.43090393390065 290,421 C 351.71611789369837,410.56909606609935 442.37415217659395,382.68521395979775 503,366 C 563.625847823406,349.31478604020225 594.2195091873226,343.82824022690835 649,363 C 703.7804908126774,382.17175977309165 782.7478110741151,426.0018251325688 827,427 C 871.2521889258849,427.9981748674312 880.7892465162167,386.16445924281663 935,385 C 989.2107534837833,383.83554075718337 1088.0952028610186,423.3403378961648 1147,433 C 1205.9047971389814,442.6596621038352 1224.829942039709,422.47418917252435 1267,412 C 1309.170057960291,401.52581082747565 1374.5850289801456,400.7629054137378 1440,400 C 1440,400 1440,500 1440,500 Z'
    );
  }
  25% {
    d: path(
      'M 0,500 C 0,500 0,400 0,400 C 39.30857072388703,407.245677642126 78.61714144777406,414.49135528425205 137,417 C 195.38285855222594,419.50864471574795 272.84000493279075,417.2802565051178 326,420 C 379.15999506720925,422.7197434948822 408.022838821063,430.38761869527684 457,417 C 505.977161178937,403.61238130472316 575.0686397829572,369.16926871377484 629,363 C 682.9313602170428,356.83073128622516 721.7026020471081,378.93530644962385 779,385 C 836.2973979528919,391.06469355037615 912.12095202861,381.0895054877297 972,387 C 1031.87904797139,392.9104945122703 1075.8135898384514,414.7066715994574 1129,410 C 1182.1864101615486,405.2933284005426 1244.6246886175854,374.0838081144407 1298,368 C 1351.3753113824146,361.9161918855593 1395.6876556912073,380.95809594277966 1440,400 C 1440,400 1440,500 1440,500 Z'
    );
  }
  50% {
    d: path(
      'M 0,500 C 0,500 0,400 0,400 C 54.2222222222222,400.7292144530768 108.4444444444444,401.45842890615364 170,411 C 231.5555555555556,420.54157109384636 300.4444444444445,438.8954988284622 345,432 C 389.5555555555555,425.1045011715378 409.7777777777777,392.9595757799975 454,384 C 498.2222222222223,375.0404242200025 566.4444444444446,389.26619805154763 623,398 C 679.5555555555554,406.73380194845237 724.4444444444443,409.97563201381183 779,400 C 833.5555555555557,390.02436798618817 897.7777777777778,366.8312738932051 952,368 C 1006.2222222222222,369.1687261067949 1050.4444444444443,394.6992724133679 1106,412 C 1161.5555555555557,429.3007275866321 1228.4444444444443,438.37163645332345 1286,435 C 1343.5555555555557,431.62836354667655 1391.7777777777778,415.8141817733383 1440,400 C 1440,400 1440,500 1440,500 Z'
    );
  }
  75% {
    d: path(
      'M 0,500 C 0,500 0,400 0,400 C 52.13811814033791,400.1988407941793 104.27623628067582,400.3976815883586 150,389 C 195.72376371932418,377.6023184116414 235.03317301763474,354.60811444074494 298,365 C 360.96682698236526,375.39188555925506 447.5910716487854,419.16986064866194 506,433 C 564.4089283512146,446.83013935133806 594.602540387224,430.71244296460725 649,428 C 703.397459612776,425.28755703539275 781.9987668023182,435.9803674929091 826,424 C 870.0012331976818,412.0196325070909 879.4023924035025,377.3660870637563 931,382 C 982.5976075964975,386.6339129362437 1076.3916635836722,430.55528425206563 1140,434 C 1203.6083364163278,437.44471574793437 1237.030953261808,400.4127759279812 1282,388 C 1326.969046738192,375.5872240720188 1383.484523369096,387.7936120360094 1440,400 C 1440,400 1440,500 1440,500 Z'
    );
  }
  100% {
    d: path(
      'M 0,500 C 0,500 0,400 0,400 C 54.24515969909976,405.7955851522999 108.49031939819952,411.5911703045998 152,418 C 195.50968060180048,424.4088296954002 228.28388210630163,431.43090393390065 290,421 C 351.71611789369837,410.56909606609935 442.37415217659395,382.68521395979775 503,366 C 563.625847823406,349.31478604020225 594.2195091873226,343.82824022690835 649,363 C 703.7804908126774,382.17175977309165 782.7478110741151,426.0018251325688 827,427 C 871.2521889258849,427.9981748674312 880.7892465162167,386.16445924281663 935,385 C 989.2107534837833,383.83554075718337 1088.0952028610186,423.3403378961648 1147,433 C 1205.9047971389814,442.6596621038352 1224.829942039709,422.47418917252435 1267,412 C 1309.170057960291,401.52581082747565 1374.5850289801456,400.7629054137378 1440,400 C 1440,400 1440,500 1440,500 Z'
    );
  }
}

/* up facing waves */
.path-0-up {
  animation: pathAnim-0-up 4s;
  animation-timing-function: linear;
  animation-iteration-count: infinite;
}
@keyframes pathAnim-0-up {
  0% {
    d: path(
      'M 0,500 C 0,500 0,100 0,100 C 41.99151559995069,116.98300653594771 83.98303119990138,133.96601307189542 140,126 C 196.01696880009862,118.03398692810458 266.0593908003453,85.11895424836602 323,86 C 379.9406091996547,86.88104575163398 423.7794055987175,121.55816993464052 470,121 C 516.2205944012825,120.44183006535948 564.8229868047847,84.6483660130719 624,76 C 683.1770131952153,67.3516339869281 752.9286471821432,85.8483660130719 807,88 C 861.0713528178568,90.1516339869281 899.4624244666421,75.95816993464052 949,70 C 998.5375755333579,64.04183006535948 1059.2216549512887,66.31895424836601 1110,78 C 1160.7783450487113,89.68104575163399 1201.6509557282034,110.76601307189542 1255,116 C 1308.3490442717966,121.23398692810458 1374.1745221358983,110.61699346405229 1440,100 C 1440,100 1440,500 1440,500 Z'
    );
  }
  25% {
    d: path(
      'M 0,500 C 0,500 0,100 0,100 C 56.17294364286593,93.5963497348625 112.34588728573186,87.192699469725 166,90 C 219.65411271426814,92.807300530275 270.7893944999384,104.8255518559625 327,116 C 383.2106055000616,127.1744481440375 444.4965347145146,137.50509310642497 500,136 C 555.5034652854854,134.49490689357503 605.2244666420028,121.15407571833765 653,103 C 700.7755333579972,84.84592428166235 746.6055987174743,61.878604020224444 801,72 C 855.3944012825257,82.12139597977556 918.3531384880998,125.33150820076457 980,134 C 1041.6468615119002,142.66849179923543 1101.981847330127,116.79536317671723 1146,106 C 1190.018152669873,95.20463682328277 1217.7194721913922,99.4870390923665 1264,101 C 1310.2805278086078,102.5129609076335 1375.140263904304,101.25648045381675 1440,100 C 1440,100 1440,500 1440,500 Z'
    );
  }
  50% {
    d: path(
      'M 0,500 C 0,500 0,100 0,100 C 42.4964360587002,83.08782833888273 84.9928721174004,66.17565667776545 149,70 C 213.0071278825996,73.82434332223455 298.5249475890986,98.38520162782095 349,101 C 399.4750524109014,103.61479837217905 414.9073375262054,84.28353681095079 454,86 C 493.0926624737946,87.71646318904921 555.8457023060796,110.48065112837588 614,126 C 672.1542976939204,141.51934887162412 725.709853249476,149.7938586755457 778,137 C 830.290146750524,124.20614132445431 881.3148846960169,90.34391416944136 945,83 C 1008.6851153039831,75.65608583055864 1085.030607966457,94.83048464668887 1143,97 C 1200.969392033543,99.16951535331113 1240.562683438155,84.33414724380317 1287,82 C 1333.437316561845,79.66585275619683 1386.7186582809225,89.83292637809842 1440,100 C 1440,100 1440,500 1440,500 Z'
    );
  }
  75% {
    d: path(
      'M 0,500 C 0,500 0,100 0,100 C 55.48240226908375,102.08331483536811 110.9648045381675,104.16662967073623 168,97 C 225.0351954618325,89.83337032926377 283.6231841164138,73.41679615242323 340,77 C 396.3768158835862,80.58320384757677 450.54245899617706,104.16618571957085 506,121 C 561.4575410038229,137.83381428042915 618.2069798988778,147.9184609692934 659,134 C 699.7930201011222,120.08153903070662 724.6296214083117,82.15997040325564 771,78 C 817.3703785916883,73.84002959674436 885.2745344678754,103.44165741768404 942,116 C 998.7254655321246,128.55834258231596 1044.2722407201875,124.07339992600816 1095,122 C 1145.7277592798125,119.92660007399184 1201.636502651375,120.26474287828337 1260,117 C 1318.363497348625,113.73525712171663 1379.1817486743125,106.86762856085832 1440,100 C 1440,100 1440,500 1440,500 Z'
    );
  }
  100% {
    d: path(
      'M 0,500 C 0,500 0,100 0,100 C 41.99151559995069,116.98300653594771 83.98303119990138,133.96601307189542 140,126 C 196.01696880009862,118.03398692810458 266.0593908003453,85.11895424836602 323,86 C 379.9406091996547,86.88104575163398 423.7794055987175,121.55816993464052 470,121 C 516.2205944012825,120.44183006535948 564.8229868047847,84.6483660130719 624,76 C 683.1770131952153,67.3516339869281 752.9286471821432,85.8483660130719 807,88 C 861.0713528178568,90.1516339869281 899.4624244666421,75.95816993464052 949,70 C 998.5375755333579,64.04183006535948 1059.2216549512887,66.31895424836601 1110,78 C 1160.7783450487113,89.68104575163399 1201.6509557282034,110.76601307189542 1255,116 C 1308.3490442717966,121.23398692810458 1374.1745221358983,110.61699346405229 1440,100 C 1440,100 1440,500 1440,500 Z'
    );
  }
}

.path-1-up {
  animation: pathAnim-1-up 4s;
  animation-timing-function: linear;
  animation-iteration-count: infinite;
}
@keyframes pathAnim-1-up {
  0% {
    d: path(
      'M 0,500 C 0,500 0,200 0,200 C 43.76288074978417,189.27611296090765 87.52576149956835,178.55222592181528 145,173 C 202.47423850043165,167.44777407818472 273.65983475151074,167.0672092736466 337,185 C 400.34016524848926,202.9327907263534 455.8348994943889,239.17893698359845 497,236 C 538.1651005056111,232.82106301640155 565.0005672709336,190.21704279195956 610,181 C 654.9994327290664,171.78295720804044 718.1628314218771,195.95289184856335 788,207 C 857.8371685781229,218.04710815143665 934.3481070415587,215.97138981378714 984,215 C 1033.6518929584413,214.02861018621286 1056.4447404118882,214.16154889628808 1100,208 C 1143.5552595881118,201.83845110371192 1207.8729313108888,189.38241460106056 1268,187 C 1328.1270686891112,184.61758539893944 1384.0635343445556,192.30879269946973 1440,200 C 1440,200 1440,500 1440,500 Z'
    );
  }
  25% {
    d: path(
      'M 0,500 C 0,500 0,200 0,200 C 50.64723147120483,201.85428536194354 101.29446294240967,203.70857072388705 152,209 C 202.70553705759033,214.29142927611295 253.4693797015662,223.02000246639537 307,214 C 360.5306202984338,204.97999753360463 416.8280182513256,178.2114194105315 480,181 C 543.1719817486744,183.7885805894685 613.2185472931312,216.13431989147858 665,219 C 716.7814527068688,221.86568010852142 750.2977925761498,195.2513010235541 806,190 C 861.7022074238502,184.7486989764459 939.5902824022692,200.86047601430508 986,201 C 1032.4097175977308,201.13952398569492 1047.3410778147738,185.30679491922555 1094,193 C 1140.6589221852262,200.69320508077445 1219.0454063386362,231.91234430879268 1282,237 C 1344.9545936613638,242.08765569120732 1392.477296830682,221.04382784560366 1440,200 C 1440,200 1440,500 1440,500 Z'
    );
  }
  50% {
    d: path(
      'M 0,500 C 0,500 0,200 0,200 C 51.08787766678999,202.55676408928352 102.17575533357999,205.11352817856704 158,210 C 213.82424466642001,214.88647182143296 274.38485633247006,222.1026513750154 326,217 C 377.61514366752994,211.8973486249846 420.2848193365397,194.4758663213713 468,198 C 515.7151806634603,201.5241336786287 568.4758663213713,225.99388333949932 626,233 C 683.5241336786287,240.00611666050068 745.8117153779751,229.54860032063138 798,209 C 850.1882846220249,188.45139967936862 892.2772721667282,157.8117153779751 941,166 C 989.7227278332718,174.1882846220249 1045.0791959551113,221.20453816746823 1099,237 C 1152.9208040448887,252.79546183253177 1205.4059440128253,237.37013195215192 1262,226 C 1318.5940559871747,214.62986804784808 1379.2970279935873,207.31493402392402 1440,200 C 1440,200 1440,500 1440,500 Z'
    );
  }
  75% {
    d: path(
      'M 0,500 C 0,500 0,200 0,200 C 50.911234430879276,196.347959057837 101.82246886175855,192.69591811567395 161,191 C 220.17753113824145,189.30408188432605 287.6213589838451,189.5642865951412 338,198 C 388.3786410161549,206.4357134048588 421.6920952028611,223.0469355037612 476,216 C 530.3079047971389,208.9530644962388 605.6102602047108,178.24797138981384 660,176 C 714.3897397952892,173.75202861018616 747.8668639782958,199.96117893698357 803,215 C 858.1331360217042,230.03882106301643 934.9222838821063,233.9073128622518 986,219 C 1037.0777161178937,204.0926871377482 1062.4440004932792,170.40956961400911 1105,172 C 1147.5559995067208,173.59043038599089 1207.3017141447774,210.45440868171167 1266,221 C 1324.6982858552226,231.54559131828833 1382.3491429276114,215.77279565914415 1440,200 C 1440,200 1440,500 1440,500 Z'
    );
  }
  100% {
    d: path(
      'M 0,500 C 0,500 0,200 0,200 C 43.76288074978417,189.27611296090765 87.52576149956835,178.55222592181528 145,173 C 202.47423850043165,167.44777407818472 273.65983475151074,167.0672092736466 337,185 C 400.34016524848926,202.9327907263534 455.8348994943889,239.17893698359845 497,236 C 538.1651005056111,232.82106301640155 565.0005672709336,190.21704279195956 610,181 C 654.9994327290664,171.78295720804044 718.1628314218771,195.95289184856335 788,207 C 857.8371685781229,218.04710815143665 934.3481070415587,215.97138981378714 984,215 C 1033.6518929584413,214.02861018621286 1056.4447404118882,214.16154889628808 1100,208 C 1143.5552595881118,201.83845110371192 1207.8729313108888,189.38241460106056 1268,187 C 1328.1270686891112,184.61758539893944 1384.0635343445556,192.30879269946973 1440,200 C 1440,200 1440,500 1440,500 Z'
    );
  }
}

.path-2-up {
  animation: pathAnim-2-up 4s;
  animation-timing-function: linear;
  animation-iteration-count: infinite;
}
@keyframes pathAnim-2-up {
  0% {
    d: path(
      'M 0,500 C 0,500 0,300 0,300 C 65.68242693303738,308.1268220495745 131.36485386607475,316.253644099149 174,325 C 216.63514613392525,333.746355900851 236.22301146873843,343.11224565297823 293,329 C 349.77698853126157,314.88775434702177 443.7431002589715,277.2973732889382 501,282 C 558.2568997410285,286.7026267110618 578.8045874953755,333.698261191269 619,332 C 659.1954125046245,330.301738808731 719.0385497595266,279.9095819459859 783,277 C 846.9614502404734,274.0904180540141 915.0412134665186,318.6634110247873 963,334 C 1010.9587865334814,349.3365889752127 1038.7965963743989,335.436773954865 1095,324 C 1151.2034036256011,312.563226045135 1235.772401035886,303.58949315575285 1298,300 C 1360.227598964114,296.41050684424715 1400.113799482057,298.2052534221236 1440,300 C 1440,300 1440,500 1440,500 Z'
    );
  }
  25% {
    d: path(
      'M 0,500 C 0,500 0,300 0,300 C 59.55067209273648,300.9489702799359 119.10134418547295,301.89794055987176 173,291 C 226.89865581452705,280.10205944012824 275.1452953508447,257.3572080404488 331,264 C 386.8547046491553,270.6427919595512 450.3174744111483,306.6732272783328 497,310 C 543.6825255888517,313.3267727216672 573.5848070045628,283.94988284622025 615,285 C 656.4151929954372,286.05011715377975 709.3432975706005,317.52724133678635 773,329 C 836.6567024293995,340.47275866321365 911.0420027130349,331.9411518066346 966,329 C 1020.9579972869651,326.0588481933654 1056.4886915772597,328.70815143667534 1112,322 C 1167.5113084227403,315.29184856332466 1243.0032309779258,299.2262424466642 1301,294 C 1358.9967690220742,288.7737575533358 1399.498384511037,294.3868787766679 1440,300 C 1440,300 1440,500 1440,500 Z'
    );
  }
  50% {
    d: path(
      'M 0,500 C 0,500 0,300 0,300 C 52.16421260328029,318.6686891108645 104.32842520656058,337.33737822172895 164,335 C 223.67157479343942,332.66262177827105 290.8505117770379,309.3191762239487 342,297 C 393.1494882229621,284.6808237760513 428.2695276852879,283.38591688247624 486,289 C 543.7304723147121,294.61408311752376 624.0713774818104,307.1371562461462 669,315 C 713.9286225181896,322.8628437538538 723.4449623874707,326.0654581329388 773,325 C 822.5550376125293,323.9345418670612 912.1487729683067,318.60101122209886 983,307 C 1053.8512270316933,295.39898877790114 1105.959945739302,277.53049697866567 1145,270 C 1184.040054260698,262.46950302133433 1210.011444074485,265.2770008632384 1257,272 C 1303.988555925515,278.7229991367616 1371.9942779627575,289.3614995683808 1440,300 C 1440,300 1440,500 1440,500 Z'
    );
  }
  75% {
    d: path(
      'M 0,500 C 0,500 0,300 0,300 C 51.141299790356385,302.2874337156246 102.28259958071277,304.5748674312493 160,303 C 217.71740041928723,301.4251325687507 282.0109014675053,295.9879639906277 327,287 C 371.9890985324947,278.0120360093723 397.6737945492661,265.47327660623995 451,268 C 504.3262054507339,270.52672339376005 585.2939203354298,288.1189295844124 643,293 C 700.7060796645702,297.8810704155876 735.1505241090147,290.0510050561105 793,293 C 850.8494758909853,295.9489949438895 932.1039832285117,309.67705019114567 985,305 C 1037.8960167714883,300.32294980885433 1062.4335429769392,277.24079417930693 1103,270 C 1143.5664570230608,262.75920582069307 1200.1618448637316,271.3597730916266 1259,279 C 1317.8381551362684,286.6402269083734 1378.919077568134,293.3201134541867 1440,300 C 1440,300 1440,500 1440,500 Z'
    );
  }
  100% {
    d: path(
      'M 0,500 C 0,500 0,300 0,300 C 65.68242693303738,308.1268220495745 131.36485386607475,316.253644099149 174,325 C 216.63514613392525,333.746355900851 236.22301146873843,343.11224565297823 293,329 C 349.77698853126157,314.88775434702177 443.7431002589715,277.2973732889382 501,282 C 558.2568997410285,286.7026267110618 578.8045874953755,333.698261191269 619,332 C 659.1954125046245,330.301738808731 719.0385497595266,279.9095819459859 783,277 C 846.9614502404734,274.0904180540141 915.0412134665186,318.6634110247873 963,334 C 1010.9587865334814,349.3365889752127 1038.7965963743989,335.436773954865 1095,324 C 1151.2034036256011,312.563226045135 1235.772401035886,303.58949315575285 1298,300 C 1360.227598964114,296.41050684424715 1400.113799482057,298.2052534221236 1440,300 C 1440,300 1440,500 1440,500 Z'
    );
  }
}

.path-3-up {
  animation: pathAnim-3-up 4s;
  animation-timing-function: linear;
  animation-iteration-count: infinite;
}
@keyframes pathAnim-3-up {
  0% {
    d: path(
      'M 0,500 C 0,500 0,400 0,400 C 57.25426069798989,378.820964360587 114.50852139597978,357.641928721174 163,370 C 211.49147860402022,382.358071278826 251.2201751140708,428.253249475891 304,433 C 356.7798248859292,437.746750524109 422.61077814773694,401.345073375262 486,395 C 549.3892218522631,388.654926624738 610.336712294981,412.36645702306083 655,426 C 699.663287705019,439.63354297693917 728.0423726723394,443.18909853249477 771,434 C 813.9576273276606,424.81090146750523 871.4937970156615,402.87714884696015 934,395 C 996.5062029843385,387.12285115303985 1063.9824392650141,393.3023060796646 1125,392 C 1186.0175607349859,390.6976939203354 1240.5764459242814,381.91362683438155 1292,382 C 1343.4235540757186,382.08637316561845 1391.7117770378593,391.0431865828092 1440,400 C 1440,400 1440,500 1440,500 Z'
    );
  }
  25% {
    d: path(
      'M 0,500 C 0,500 0,400 0,400 C 50.752250585768905,413.5602910346529 101.50450117153781,427.1205820693057 155,418 C 208.4954988284622,408.8794179306943 264.7342458996177,377.07796275743004 325,376 C 385.2657541003823,374.92203724256996 449.5585152299915,404.56756690097427 507,418 C 564.4414847700085,431.43243309902573 615.0316931804168,428.65176963867304 665,426 C 714.9683068195832,423.34823036132696 764.3147120483414,420.82535454433344 807,415 C 849.6852879516586,409.17464545566656 885.7094586262176,400.0468121839931 943,391 C 1000.2905413737824,381.9531878160069 1078.8474534467878,372.9873967196942 1132,376 C 1185.1525465532122,379.0126032803058 1212.900727586632,394.0036009372302 1260,400 C 1307.099272413368,405.9963990627698 1373.549636206684,402.9981995313849 1440,400 C 1440,400 1440,500 1440,500 Z'
    );
  }
  50% {
    d: path(
      'M 0,500 C 0,500 0,400 0,400 C 59.11421876926872,406.6476260944629 118.22843753853743,413.2952521889259 176,420 C 233.77156246146257,426.7047478110741 290.20046861511906,433.4666173387594 343,436 C 395.79953138488094,438.5333826612406 444.9696880009865,436.8382784560365 496,424 C 547.0303119990135,411.1617215439635 599.9207793809348,387.18026883709456 655,373 C 710.0792206190652,358.81973116290544 767.3471944752743,354.44064619558515 824,365 C 880.6528055247257,375.55935380441485 936.6904427179677,401.0571463805648 984,411 C 1031.3095572820323,420.9428536194352 1069.8910346528548,415.33076828215565 1115,406 C 1160.1089653471452,396.66923171784435 1211.7454186706127,383.6197804908126 1267,382 C 1322.2545813293873,380.3802195091874 1381.1272906646936,390.19010975459366 1440,400 C 1440,400 1440,500 1440,500 Z'
    );
  }
  75% {
    d: path(
      'M 0,500 C 0,500 0,400 0,400 C 48.02762362806757,422.9977555802195 96.05524725613515,445.995511160439 152,435 C 207.94475274386485,424.004488839561 271.80663460352696,379.0157109384635 325,378 C 378.19336539647304,376.9842890615365 420.7182143297571,419.94164508570725 470,436 C 519.2817856702429,452.05835491429275 575.3205080774449,441.2177087187076 632,435 C 688.6794919225551,428.7822912812924 745.9997533604637,427.1875200394623 801,427 C 856.0002466395363,426.8124799605377 908.6804784807005,428.0322111234431 959,424 C 1009.3195215192995,419.9677888765569 1057.2783327167344,410.6836354667653 1113,398 C 1168.7216672832656,385.3163645332347 1232.2061906523616,369.2332470094956 1288,369 C 1343.7938093476384,368.7667529905044 1391.8969046738193,384.3833764952522 1440,400 C 1440,400 1440,500 1440,500 Z'
    );
  }
  100% {
    d: path(
      'M 0,500 C 0,500 0,400 0,400 C 57.25426069798989,378.820964360587 114.50852139597978,357.641928721174 163,370 C 211.49147860402022,382.358071278826 251.2201751140708,428.253249475891 304,433 C 356.7798248859292,437.746750524109 422.61077814773694,401.345073375262 486,395 C 549.3892218522631,388.654926624738 610.336712294981,412.36645702306083 655,426 C 699.663287705019,439.63354297693917 728.0423726723394,443.18909853249477 771,434 C 813.9576273276606,424.81090146750523 871.4937970156615,402.87714884696015 934,395 C 996.5062029843385,387.12285115303985 1063.9824392650141,393.3023060796646 1125,392 C 1186.0175607349859,390.6976939203354 1240.5764459242814,381.91362683438155 1292,382 C 1343.4235540757186,382.08637316561845 1391.7117770378593,391.0431865828092 1440,400 C 1440,400 1440,500 1440,500 Z'
    );
  }
}
