.projects-wrap {
  display: flex;
  flex-direction: column;
  justify-content: space-around;
  align-items: center;
  background-image: url('../../assets/bg_dark_pattern.png');
  background-repeat: repeat;
  background-blend-mode: screen;
  background-color: #12121266;
  -webkit-box-shadow: -4px 11px 35px 2px rgba(0, 0, 0, 0.74);
  box-shadow: -4px 11px 35px 2px rgba(0, 0, 0, 0.74);
  min-height: 25vh;
  margin: 2rem 4rem;
  padding: 1rem 2rem;
  min-width: 375px;
}

.fullImage {
  opacity: 100;
}

.projects-container {
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  justify-content: center;
}

.section-title {
  padding: 1rem 0px 1rem 0px;
  color: #fff;
  opacity: 87%;
}

.project-content {
  display: flex;
  flex-direction: column;
  align-items: center;
  text-align: center;
  padding: 1rem 1rem;
}

.details {
  padding: 1rem 1rem;
  display: flex;
  justify-content: center;
  flex-direction: column;
  align-items: center;
  opacity: 0;
  height: 0;
  width: 0;
  overflow: hidden;
}

.project-details {
  padding: 1rem 2rem;
  color: #eee;
  text-align: left;
  font-size: 16px;
}
.visible {
  height: auto;
  width: 100%;
  opacity: 1;
}

.visible img {
  width: 100%;
  height: 100%;
}

.project-image-container {
  height: auto;
}

.project-image-container:hover {
  cursor: pointer;
  animation: anim 0.8s both;
}

@keyframes anim {
  0%,
  100% {
    -webkit-transform: translateX(0%);
    transform: translateX(0%);
    -webkit-transform-origin: 50% 50%;
    transform-origin: 50% 50%;
  }
  15% {
    -webkit-transform: translateX(-30px) rotate(6deg);
    transform: translateX(-30px) rotate(6deg);
  }
  30% {
    -webkit-transform: translateX(15px) rotate(-6deg);
    transform: translateX(15px) rotate(-6deg);
  }
  45% {
    -webkit-transform: translateX(-15px) rotate(3.6deg);
    transform: translateX(-15px) rotate(3.6deg);
  }
  60% {
    -webkit-transform: translateX(9px) rotate(-2.4deg);
    transform: translateX(9px) rotate(-2.4deg);
  }
  75% {
    -webkit-transform: translateX(-6px) rotate(1.2deg);
    transform: translateX(-6px) rotate(1.2deg);
  }
}

.project-title {
  color: #fff;
  opacity: 87%;
}

.project-desc {
  color: #fff;
  opacity: 66%;
}

.project-image-container img {
  width: 100%;
  max-width: 300px;
  height: auto;
  border-radius: 0px 40px 40px 40px;
  border: 0.66rem solid rgba(0, 215, 255, 1);
}

@media screen and (max-width: 750px) {
  .details {
    padding: 0;
  }
  .projects-container {
    height: auto;
    width: auto;
    min-width: 375px;
    flex-direction: column;
    width: 100%;
  }

  .projects-wrap {
    margin: 0 auto;
    margin-top: 2rem;
  }

  .project-content {
    display: flex;
    flex-direction: column;
  }

  .projects-container img {
    max-height: 200px;
    width: auto;
  }

  .project-content p {
    color: #fff;
    opacity: 87%;
  }

  .visible img {
    width: 85vw;
    max-height: 100vh;
  }
}
