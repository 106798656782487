.about-container {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  background-image: url('../../assets/bg_dark_pattern.png');
  background-repeat: repeat;
  background-blend-mode: screen;
  background-color: #12121266;
  -webkit-box-shadow: -4px 11px 35px 2px rgba(0, 0, 0, 0.74);
  box-shadow: -4px 11px 35px 2px rgba(0, 0, 0, 0.74);
  min-height: 25vh;
  margin: 2rem 4rem;
  padding: 1rem 2rem;
}

.section-title {
  padding: 1rem 0px 1rem 0px;
  color: #fff;
  opacity: 87%;
}

.about-content {
  display: flex;
  align-items: center;
  background: radial-gradient(
      circle at 50% 50%,
      rgba(181, 255, 104, 1) 0%,
      rgba(0, 215, 255, 1) 100%
    )
    fixed;
  background: conic-gradient(
      from 90deg at 50% 50%,
      rgba(181, 255, 104, 1) 0%,
      rgba(0, 215, 255, 1) 50%,
      rgba(181, 255, 104, 1) 100%
    )
    fixed;
  -webkit-background-clip: text;
  color: transparent;
  text-align: center;
}

.about-content p {
  line-height: 1.35;
  opacity: 100;
  text-shadow: 2px 2px 0.75rem black;
}

.me-img {
  border-radius: 0px 40px 40px 40px;
  border: 0.66rem solid rgba(0, 215, 255, 1);
  height: 300px;
  width: auto;
  margin: 2rem 4rem;
}

@media screen and (max-width: 1000px) {
  .about-container {
    height: auto;
    width: auto;
    min-width: 375px;
  }
  .about-content {
    display: flex;
    flex-direction: column;
  }
  .about-container img {
    max-height: 200px;
    width: auto;
  }
  .about-content p {
    color: #fff;
    opacity: 87%;
    padding: 1rem 2rem;
  }
}

@media screen and (max-width: 750px) {
  .about-container {
    margin: 0 auto;
  }
}
